import type {
  Customer,
  PersonalDataOneOf1 as CompanyPersonalData,
  PrivatePersonalData,
} from "~/src/generated-sources/core";

export default (customer: Customer, short: boolean) => {
  if (customer.customerType === "private") {
    const personalData = customer.personalData as PrivatePersonalData;

    if (short) {
      return personalData.firstName;
    } else {
      return personalData.firstName + " " + personalData.lastName;
    }
  } else {
    const personalData = customer.personalData as CompanyPersonalData;

    if (short) {
      return personalData.companyName;
    } else {
      return `${personalData.companyName}${
        personalData.careOf ? ` (z.Hd. ${personalData.careOf})` : ""
      }`;
    }
  }
};
